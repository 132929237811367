import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Card, CardActions, CardContent, Button, TextField, Box, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

const SmallTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    height: 22, // Adjust the height as needed
  },
});

const BGPBlackhole = () => {
  const [customerIP, setCustomerIP] = useState('');
  const [message, setMessage] = useState('');

  const handleBanClick = () => {
    banIP('ban');
  };

  const handleUnbanClick = () => {
    banIP('unban');
  };

  const banIP = (action) => {
    const entry = {
      customerIP: customerIP,
      action: action,
    };

    fetch('/api/checkIP', {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(entry),
      cache: 'no-cache',
      headers: new Headers({
        'content-type': 'application/json',
      }),
    })
      .then((response) => {
        if (response.status !== 200) {
          console.log(`response status not 200: ${response.status}`);
          return;
        }
        response.json().then((data) => {
          console.log(data);
          setMessage(data.message);
        });
      });
  };

  return (
    <Container sx={{ display: 'flex', alignItems: 'center', overflow: 'auto'}}>
      <Card variant="outlined">
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
            Ban target IP from all edge routers:
          </Typography>
          Target IP:{' '}
          <SmallTextField
            type="text"
            name="customerIP"
            id="customerIP"
            placeholder="e.g., 192.168.1.1"
            value={customerIP}
            onChange={(e) => setCustomerIP(e.target.value)}
          />
          <Box id="dpData">
            {message && <p>{message}</p>}
          </Box>
        </CardContent>
        <CardActions>
          <Button
            type="button"
            variant="contained"
            color="error"
            size="small"
            name="btn"
            id="ban"
            onClick={handleBanClick}
          >
            Ban
          </Button>
          <Button
            type="button"
            variant="contained"
            color="success"
            size="small"
            name="btn"
            id="unban"
            onClick={handleUnbanClick}
          >
            Unban
          </Button>
        </CardActions>
      </Card>
    </Container>
  );
};

export default BGPBlackhole;
