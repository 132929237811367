import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Dashboard from './components/Dashboard/Dashboard';
import DashboardContent from './components/DashboardContent';
import BGPBlackhole from './components/BGPBlackhole';
import Cloudpath from './components/Cloudpath';
import SiteSpeedtest from './components/SiteSpeedtest';
import BGPTrafficShift from './components/BGPTrafficShift';
import IPDuplicationReport from './components/IPDuplicationReport';
import EquipmentDatabase from './components/EquipmentDatabase';

const AppRoutes = ({ username, handleLogout }) => {
  return (
    <Routes>
      <Route path="/" element={<DashboardContent />} />
      <Route path="/bgp-blackhole" element={<BGPBlackhole />} />
      <Route path="/cloudpath" element={<Cloudpath />} />
      <Route path="/site-speedtest" element={<SiteSpeedtest />} />
      <Route path="/bgp-traffic-shift" element={<BGPTrafficShift />} />
      <Route path="/ip-duplication-report" element={<IPDuplicationReport />} />
      <Route path="/equipment-database" element={<EquipmentDatabase />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
