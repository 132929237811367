import React from 'react';
import './Dashboard.css';
import { styled } from '@mui/system';
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import {
  useLocation,
  useNavigate,
  useMatch,
} from 'react-router-dom';
import {
  ExitToApp,
  Assessment,
  Cloud,
  Speed,
  Layers,
  ChangeCircle,
  Storage,
  RemoveCircle,
} from '@mui/icons-material';
import zentroLogo from '../../assets/zentroLogo.png';
import AppRoutes from '../../routes'

const drawerWidth = 210;

const Main = styled('div')(({ theme }) => ({
  display: 'flex',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
}));

const Content = styled('div')(({ theme }) => ({
  width: `calc(100% - 210px)`, // Adjust the value to consider the width of the drawer
  overflow: 'auto', // Enable scrolling for the content
  marginTop: '48px'
}));

const Logo = styled('img')({
  width: 'auto',
  height: '32px',
  marginLeft: '8px',
  marginRight: '8px',
});

const LogoContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const LogoutButton = styled(ListItem)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
}));

const StatusBar = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '48px',
  padding: '0 16px',
  backgroundColor: '#EFEFEF',
  position: 'fixed',
  top: 0,
  right: 0,
  left: `${drawerWidth}px`,
}));

const Dashboard = ({ username, handleLogout }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    { label: 'Dashboard', icon: <Layers />, path: '/' },
    { label: 'BGP Blackhole', icon: <RemoveCircle />, path: '/bgp-blackhole' },
    { label: 'Cloudpath', icon: <Cloud />, path: '/cloudpath' },
    { label: 'Site Speedtest', icon: <Speed />, path: '/site-speedtest' },
    { label: 'BGP Traffic Shift', icon: <ChangeCircle />, path: '/bgp-traffic-shift' },
    { label: 'IP Duplication Report', icon: <Assessment />, path: '/ip-duplication-report' },
    { label: 'Equipment Database', icon: <Storage />, path: '/equipment-database' },
  ];

  const handleMenuClick = (path) => {
    navigate(path);
  };

  const handleLogoutClick = () => {
    // Call the handleLogout function or trigger the logout route here
    handleLogout();
  };

  const isDashboard = location.pathname === '/';

  return (
    <Main>
      <StyledDrawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
      >
        <Toolbar>
          <LogoContainer>
            <Logo src={zentroLogo} alt="Zentro Logo" />
          </LogoContainer>
        </Toolbar>
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.label}
                selected={location.pathname === item.path}
                onClick={() => handleMenuClick(item.path)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItem>
            ))}
          </List>
          <LogoutButton button onClick={handleLogoutClick}>
            <ListItemIcon>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </LogoutButton>
        </Box>
        <StatusBar>
          <div>
            {isDashboard
              ? 'Dashboard'
              : (menuItems.find((item) => item.path === location.pathname) || {})
                .label}
          </div>
          <div>Logged as {username}</div>
        </StatusBar>
      </StyledDrawer>
      <Content>
        <AppRoutes />
      </Content>
    </Main>
  );
};

export default Dashboard;
