import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, Tab, Tabs } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
};

const formatBatteryRuntime = (seconds) => {
  const time = Math.floor(seconds / 100);
  const minutes = Math.floor((time % 3600) / 60);
  const hours = Math.floor((time % 86400) / 3600);
  const days = Math.floor(time / 86400);
  let formattedTime = "";
  if (time < 60) {
    formattedTime = "";
  } else {
    if (days > 0) {
      formattedTime += `${days}d `;
    }
    if (hours > 0) {
      formattedTime += `${hours}hr `;
    }
    if (minutes > 0) {
      formattedTime += `${minutes}min`;
    }
  }
  return formattedTime;
};

const EquipmentDatabase = () => {
  const [data, setData] = useState([]);
  const [csvExporter, setCsvExporter] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);

  const columnRouter = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 80,
    },
    {
      accessorKey: 'hostname',
      header: 'Hostname',
      size: 150,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 120,
    },
    {
      accessorKey: 'ip',
      header: 'IP',
      size: 150,
    },
    {
      accessorKey: 'location',
      header: 'Location',
      size: 200,
    },
    {
      accessorKey: 'make',
      header: 'Make',
      size: 120,
    },
    {
      accessorKey: 'firmware',
      header: 'Firmware',
      size: 120,
    },
    {
      accessorKey: 'model',
      header: 'Model',
      size: 200,
    },
    {
      accessorKey: 'serial_number',
      header: 'Serial Number',
      size: 180,
    },
    {
      accessorKey: 'initial_discovered',
      header: 'Initial Discovered Date',
      size: 200,
      Cell: ({ row }) => {
        const { initial_discovered } = row.original;
        if (initial_discovered === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(initial_discovered);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      accessorKey: 'last_updated',
      header: 'Last Updated Date',
      size: 200,
      Cell: ({ row }) => {
        const { last_updated } = row.original;
        if (last_updated === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(last_updated);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
  ];

  const columnUPS = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 60,
    },
    {
      accessorKey: 'hostname',
      header: 'Hostname',
      size: 150,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 100,
    },
    {
      accessorKey: 'ip',
      header: 'IP',
      size: 150,
    },
    {
      accessorKey: 'discovered_from',
      header: 'Discovered Router',
      size: 150,
    },
    {
      accessorKey: 'mac_address',
      header: 'MAC',
      size: 150,
    },
    {
      accessorKey: 'location',
      header: 'Location',
      size: 200,
    },
    {
      accessorKey: 'battery_percentage',
      header: 'Percentage',
      size: 150,
      Cell: ({ row }) => {
        const { battery_percentage } = row.original;
        if (battery_percentage === null) {
          return ''; // or any other representation for null value
        }
        return `${battery_percentage}%`;
      },
    },
    {
      accessorKey: 'battery_runtime',
      header: 'Battery Runtime',
      size: 150,
      Cell: ({ row }) => formatBatteryRuntime(row.original.battery_runtime),
    },
    {
      accessorKey: 'ups_manufacture_date',
      header: 'UPS Manufacture Date',
      size: 180,
      Cell: ({ row }) => {
        const { ups_manufacture_date } = row.original;
        if (ups_manufacture_date === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(ups_manufacture_date);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      accessorKey: 'battery_replacement_date',
      header: 'Battery Replacement Date',
      size: 200,
      Cell: ({ row }) => {
        const { battery_replacement_date } = row.original;
        if (battery_replacement_date === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(battery_replacement_date);
        const formattedDate = date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      accessorKey: 'battery_external',
      header: 'External Batteries',
      size: 150,
    },
    {
      accessorKey: 'make',
      header: 'Make',
      size: 100,
    },
    {
      accessorKey: 'model',
      header: 'Model',
      size: 150,
    },
    {
      accessorKey: 'ups_serial_number',
      header: 'UPS Serial Number',
      size: 180,
    },
    {
      accessorKey: 'snmp_serial_number',
      header: 'SNMP Serial Number',
      size: 180,
    },
    {
      accessorKey: 'initial_discovered',
      header: 'Initial Discovered Date',
      size: 200,
      Cell: ({ row }) => {
        const { initial_discovered } = row.original;
        if (initial_discovered === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(initial_discovered);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      accessorKey: 'last_updated',
      header: 'Last Updated Date',
      size: 200,
      Cell: ({ row }) => {
        const { last_updated } = row.original;
        if (last_updated === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(last_updated);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
  ];

  const columnSW = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 60,
    },
    {
      accessorKey: 'hostname',
      header: 'Hostname',
      size: 150,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 100,
    },
    {
      accessorKey: 'ip',
      header: 'IP',
      size: 150,
    },
    {
      accessorKey: 'discovered_from',
      header: 'Discovered Router',
      size: 150,
    },
    {
      accessorKey: 'mac_address',
      header: 'MAC',
      size: 150,
    },
    {
      accessorKey: 'make',
      header: 'Make',
      size: 100,
    },
    {
      accessorKey: 'model',
      header: 'Model',
      size: 150,
    },
    {
      accessorKey: 'serial_number',
      header: 'Switch Serial Number',
      size: 180,
    },
    {
      accessorKey: 'initial_discovered',
      header: 'Initial Discovered Date',
      size: 200,
      Cell: ({ row }) => {
        const { initial_discovered } = row.original;
        if (initial_discovered === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(initial_discovered);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      accessorKey: 'last_updated',
      header: 'Last Updated Date',
      size: 200,
      Cell: ({ row }) => {
        const { last_updated } = row.original;
        if (last_updated === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(last_updated);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
  ];

  const columnDevice = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 60,
    },
    {
      accessorKey: 'mac_address',
      header: 'MAC',
      size: 150,
    },
    {
      accessorKey: 'ip',
      header: 'IP',
      size: 130,
    },
    {
      accessorKey: 'discovered_from',
      header: 'Discovered Router',
      size: 130,
    },
    {
      accessorKey: 'initial_discovered',
      header: 'Initial Discovered Date',
      size: 200,
      Cell: ({ row }) => {
        const { initial_discovered } = row.original;
        if (initial_discovered === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(initial_discovered);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
    {
      accessorKey: 'last_updated',
      header: 'Last Updated Date',
      size: 200,
      Cell: ({ row }) => {
        const { last_updated } = row.original;
        if (last_updated === null) {
          return ''; // or any other representation for null value
        }
        const date = new Date(last_updated);
        const formattedDate = date.toLocaleTimeString('en-US', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });
        return formattedDate;
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (tabs[currentTab].apiUrl != null && tabs[currentTab].column != null) {
        try {
          const response = await fetch(tabs[currentTab].apiUrl);
          const data = await response.json();
          setData(data);
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [currentTab]);

  useEffect(() => {
    if (data.length > 0 && tabs[currentTab].column != null) {
      const headers = tabs[currentTab].column.map((column) => column.header);
      const filename = `Zentro ${tabs[currentTab].label} Database Export ${new Date().toLocaleString().replace(/[:/]/g, '-')}.csv`;
      setCsvExporter(new ExportToCsv({ ...csvOptions, headers, filename }));
    }
  }, [data, currentTab]);

  const handleExportData = () => {
    const mappedData = data.map((row) =>
      tabs[currentTab].column.reduce((rowData, column) => {
        if (column.Cell) {
          // If the column has a custom Cell component, use its rendered value
          rowData[column.header] = column.Cell({ row: { original: row } }); // Pass the original row object
        } else {
          // Otherwise, use the raw value from the accessorKey
          rowData[column.header] = row[column.accessorKey];
        }
        return rowData;
      }, {})
    );
    csvExporter.generateCsv(mappedData);
  };

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row));
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const tabs = [
    { label: 'Router', apiUrl: '/api/equipmentRouter', column: columnRouter },
    { label: 'Switch', apiUrl: '/api/equipmentSwitch', column: columnSW },
    { label: 'Radio', apiUrl: null, column: null },
    { label: 'GAM', apiUrl: null, column: null },
    { label: 'UPS', apiUrl: '/api/equipmentUPS', column: columnUPS },
    { label: 'WAP', apiUrl: null, column: null },
    { label: 'All Device', apiUrl: '/api/equipmentDevice', column: columnDevice },
  ];

  return (
    <Box>
      <Tabs value={currentTab} onChange={handleTabChange} sx={{
        borderRadius: '5px', // Adjust the value as needed
        backgroundColor: '#f0f0f0', // Set the background color for the tab
        // Additional styles for the tab
      }}>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} />
        ))}
      </Tabs>
      
      {tabs[currentTab].apiUrl && tabs[currentTab].column && (
        <MaterialReactTable
          key={currentTab}  
          columns={tabs[currentTab].column}
          data={data}
          enableRowSelection
          enableColumnResizing
          positionToolbarAlertBanner="bottom"
          initialState={{ density: 'compact' }}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Button
                color="info"
                onClick={handleExportData}
                startIcon={<FileDownloadIcon />}
                variant="contained"
                size="small"
              >
                Export All
              </Button>
              <Button
                color="info"
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() => handleExportRows(table.getPrePaginationRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
                size="small"
              >
                Export Filtered
              </Button>
              <Button
                color="info"
                disabled={table.getRowModel().rows.length === 0}
                onClick={() => handleExportRows(table.getRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
                size="small"
              >
                Export Page
              </Button>
            </Box>
          )}
        />
      )}
    </Box >
  );
};

export default EquipmentDatabase;